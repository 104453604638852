import { createPlugin } from '@fullcalendar/core';
import premiumCommonPlugin from '@fullcalendar/premium-common';
import resourcePlugin from '@fullcalendar/resource';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ResourceDayTimeColsView } from './internal.js';
import '@fullcalendar/core/internal';
import '@fullcalendar/core/preact';
import '@fullcalendar/timegrid/internal';
import '@fullcalendar/resource/internal';
import '@fullcalendar/resource-daygrid/internal';

var index = createPlugin({
    name: '@fullcalendar/resource-timegrid',
    premiumReleaseDate: '2023-02-07',
    deps: [
        premiumCommonPlugin,
        resourcePlugin,
        timeGridPlugin,
    ],
    initialView: 'resourceTimeGridDay',
    views: {
        resourceTimeGrid: {
            type: 'timeGrid',
            component: ResourceDayTimeColsView,
            needsResourceData: true,
        },
        resourceTimeGridDay: {
            type: 'resourceTimeGrid',
            duration: { days: 1 },
        },
        resourceTimeGridWeek: {
            type: 'resourceTimeGrid',
            duration: { weeks: 1 },
        },
    },
});

export { index as default };
